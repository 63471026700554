import axios from 'axios';

const LOCAL = false;

const BASE_URL = 'https://api.futaapredict.com';
const LOCAL_URL = 'http://127.0.0.1:8000';

export default axios.create({
    baseURL: LOCAL ? LOCAL_URL : BASE_URL
});
